import React, { useState, useEffect, useRef } from "react";
import "./custom-styles.css";
import content from "./content.json";
import {
  ArrowTurnDownLeftIcon,
  RocketLaunchIcon,
  MoonIcon,
  SunIcon,
  PencilSquareIcon,
  ClipboardDocumentListIcon,
  UsersIcon,
  BoltIcon,
  BookOpenIcon,
  EnvelopeIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ScissorsIcon,
  StarIcon,
  LightBulbIcon,
  BuildingLibraryIcon,
  GlobeAltIcon,
} from "@heroicons/react/16/solid";

// import { SunIcon } from "@heroicons/react/24/outline";

const Icon = ({ name }) => (
  <span className='icon' role='img' aria-label={name}>
    {/* {name === "graph" && "📊"} */}
    {name === "zap" && <RocketLaunchIcon className='icon' />}
    {name === "expertise" && <StarIcon className='icon' />}
    {name === "team" && <UsersIcon className='icon' />}
    {name === "approach" && <ScissorsIcon className='icon' />}
    {name === "blogs" && <PencilSquareIcon className='icon' />}
    {name === "mail" && <EnvelopeIcon className='icon' />}
    {name === "chevron-right" && "▸"}
    {name === "lightbulb" && <BoltIcon className='icon' />}
    {/* {name === "chart" && "📈"} */}
    {name === "industries" && <BuildingLibraryIcon className='icon' />}
    {name === "usecases" && <LightBulbIcon className='icon' />}
    {name === "gear" && <ClipboardDocumentListIcon className='icon' />}
    {name === "what-kg" && <BookOpenIcon className='icon' />}
  </span>
);

const Section = ({ title, children, icon, subtitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className='mb-12 pb-4 add-padding'>
      <h2 className='text-2xl font-semibold mb-2 text-green-400 flex items-center'>
        <Icon name={icon} />
        {title}
      </h2>
      {subtitle && (
        <>
          <div
            className='text-sm text-green-500 mb-2 cursor-pointer flex items-center'
            onClick={() => setIsOpen(!isOpen)}>
            <Icon name='info' />
            <span className='underline'>
              Click to {isOpen ? "hide" : "show"} about Knowledge Graphs
            </span>
          </div>
          {isOpen && (
            <div className='mb-4 p-4 bg-green-900 rounded-lg text-green-100'>
              {subtitle}
            </div>
          )}
        </>
      )}
      {children}
    </section>
  );
};

const ThemeToggle = ({ theme, toggleTheme }) => (
  <button onClick={toggleTheme} className='theme-toggle p-2 rounded-full'>
    {theme === "dark" ? (
      <SunIcon className='w-6 h-6' />
    ) : (
      <MoonIcon className='w-6 h-6' />
    )}
  </button>
);
const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='accordion-item'>
      <button className='accordion-button' onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        <span>{isOpen ? "−" : "+"}</span>
      </button>
      {isOpen && <div className='accordion-content'>{content}</div>}
    </div>
  );
};

const TeamMember = ({ name, role, image, description }) => (
  <div>
    <div className='team-member'>
      <img
        src={image}
        alt={name}
        className='w-48 h-48 rounded-full mx-auto mb-2'
      />
      <div className='team-member-info'>
        <h3 className='font-bold'>
          <a
            href={`https://www.linkedin.com/in/maruthi-prithivirajan-lokananthan/`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: "var(--text-color)" }}>
            {name}
          </a>
        </h3>
        <p>{role}</p>
      </div>
    </div>
    <p>{description}</p>
  </div>
);

const MediumBlogPosts = () => {
  const posts = content.sections.ourBlog.posts;
  const carouselRef = useRef(null);

  const scroll = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = direction === "left" ? -300 : 300;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const truncateDescription = (description, maxLength = 120) => {
    if (description.length <= maxLength) return description;
    return description.slice(0, maxLength).trim() + "...";
  };

  return (
    <div className='blog-carousel-container'>
      <button
        className='blog-carousel-control left'
        onClick={() => scroll("left")}>
        <ChevronLeftIcon className='w-6 h-6' />
      </button>
      <div className='blog-carousel' ref={carouselRef}>
        <div className='blog-carousel-inner'>
          {posts.map((post, index) => (
            <div className='blog-carousel-item' key={index}>
              <div className='blog-card'>
                <h3
                  className='blog-card-title'
                  style={{ color: "var(--text-color)" }}>
                  <a
                    href={post.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: "inherit" }}>
                    {post.title}
                  </a>
                </h3>
                <div className='blog-card-content'>
                  {post.pubDate && (
                    <p className='blog-card-date'>{post.pubDate}</p>
                  )}
                  <p className='blog-card-description'>
                    {truncateDescription(post.description)}
                  </p>
                  <a
                    href={post.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='blog-card-link'>
                    Read more &gt;
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        className='blog-carousel-control right'
        onClick={() => scroll("right")}>
        <ChevronRightIcon className='w-6 h-6' />
      </button>
    </div>
  );
};

const LandingPage = () => {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <div className={`${theme}`} data-theme={theme}>
      <header className='header'>
        <div className='container'>
          <div className='header-toggle'>
            <h1>
              <ArrowTurnDownLeftIcon className='icon-title' />
              {content.header.title}
            </h1>
            <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
          </div>
          <p className='header-subtitle'>{content.header.subtitle}</p>
        </div>
      </header>

      <main className='container'>
        <Section title={content.sections.transformData.title} icon='zap'>
          <p>{content.sections.transformData.content}</p>
        </Section>

        <Section
          title={content.sections.whyKnowledgeGraphs.title}
          icon='lightbulb'>
          <ul className='no-bullets'>
            {content.sections.whyKnowledgeGraphs.list.map((item, index) => (
              <li key={index}>
                <Icon name='chevron-right' /> {item}
              </li>
            ))}
          </ul>
        </Section>

        <Section title={content.sections.ourServices.title} icon='gear'>
          <div>
            {content.sections.ourServices.services.map((service, index) => (
              <AccordionItem
                key={index}
                title={service.title}
                content={service.content}
              />
            ))}
          </div>
        </Section>
        <Section title={content.sections.useCases.title} icon='usecases'>
          <ul className='no-bullets'>
            {content.sections.useCases.list.map((item, index) => (
              <li key={index}>
                <Icon name='chevron-right' /> {item}
              </li>
            ))}
          </ul>
        </Section>
        <Section title={content.sections.industries.title} icon='industries'>
          <ul className='no-bullets'>
            {content.sections.industries.list.map((item, index) => (
              <li key={index}>
                <Icon name='chevron-right' /> {item}
              </li>
            ))}
          </ul>
        </Section>
        <Section
          title={content.sections.graphersExpertise.title}
          icon='expertise'>
          <div className='info-box'>
            <ul className='no-bullets'>
              {content.sections.graphersExpertise.list.map((item, index) => (
                <li key={index}>
                  <Icon name='chevron-right' style={{ fontSize: "1rem" }} />{" "}
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </Section>

        <Section
          title={content.sections.graphersApproach.title}
          icon='approach'>
          <div className='info-box'>
            {content.sections.graphersApproach.steps.map((step, index) => (
              <AccordionItem
                key={index}
                title={step.title}
                content={step.content}
              />
            ))}
          </div>
        </Section>

        <Section title={content.sections.ourTeam.title} icon='team'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
            {content.sections.ourTeam.members.map((member, index) => (
              <TeamMember key={index} {...member} />
            ))}
          </div>
        </Section>

        <Section title={content.sections.ourBlog.title} icon='blogs'>
          <MediumBlogPosts />
        </Section>

        <Section>
          <h2>{content.sections.readyToGetStarted.title}</h2>
          <p>{content.sections.readyToGetStarted.content}</p>
          <a
            href={content.sections.readyToGetStarted.contactLink}
            className='carousel-card-link'>
            <Icon name='mail' style={{ fontSize: "1rem" }} />
            {content.sections.readyToGetStarted.contactText}
          </a>
        </Section>
      </main>

      <footer
        style={{
          marginTop: "2rem",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "2rem",
        }}>
        <p className='p-color'>{content.footer.text}</p>
      </footer>
    </div>
  );
};

export default LandingPage;
